import './index.css';
const Footer = () => {
    return ( 
        <div className="footer">
        <ul>
            <span className="contact-us">Contact Us</span>
            <li>Tel: 07489384726</li>
            <li>Email: contact@carbonaltdel.com</li>
        </ul>
        </div>

     );
}
 
export default Footer;
